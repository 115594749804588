import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  RefObject,
} from "react";
import cx from "classnames";
import styles from "./Map.module.css";
import {
  MapContainer,
  TileLayer,
  LayerGroup,
  GeoJSON,
  useMap,
} from "react-leaflet";
import * as L from "leaflet";
import Proj from "proj4leaflet";
import "proj4";
import "proj4leaflet";
import "leaflet/dist/leaflet.css";
import { Area, MapInner } from "./MapInner";
import { Mapper } from "./Mapper";

interface Props {}

interface Coords {
  x: number;
  y: number;
}

function getArea(plot: RefObject<HTMLDivElement>) {
  if (!plot.current) {
    return {
      width: 0,
      height: 0,
    };
  }
  const box = plot.current.getBoundingClientRect();
  const width = box.width;
  const height = box.height;
  return {
    width,
    height,
  };
}

export const Map: React.FunctionComponent<Props> = ({}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any>(null);
  const [nodes, setNodes] = useState<Coords[]>([]);
  // const pathRef = useRef<any>();
  const hasRequested = useRef<boolean>(false);
  const [d3, setD3] = useState<any>();

  const [area, setArea] = useState<Area>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (hasRequested.current) {
      return;
    }

    hasRequested.current = true;

    const promises = [
      fetch("/maps/ficker.geojson", {
        // fetch("/maps/border_sweref99.geojson", {
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }),
      window.importScript("/libs/d3_7.8.4.min.js"),
    ];

    Promise.all(promises)
      .then((responses) => {
        responses[0].json().then((json: any) => {
          setData(json);
          setArea(getArea(mapRef));
        });
        setD3(responses[1]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const onClick = useCallback((ev: any) => {
    console.log("ev", ev);
    setNodes((prev) => [...prev, { x: ev.clientX, y: ev.clientY }]);
  }, []);

  // SWEREF99 TM (EPSG:3006) with map's pixel origin at SWEREF99 TM coordinate (0, 0)
  // var crs = new L.Proj.CRS(
  //   "EPSG:3006",
  //   "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
  //   {
  //     resolutions: [8192, 4096, 2048, 1024, 512, 256, 128],
  //     origin: [0, 0],
  //   }
  // );

  return (
    <div id="map-wrapper" ref={mapRef} className={styles.wrapper}>
      <Mapper data={data} area={area} d3={d3} />
    </div>
  );

  return (
    <div id="map-wrapper" ref={mapRef} className={styles.wrapper}>
      <MapContainer
        center={[62.73996209058845, 17.691535062377554]}
        zoom={14}
        style={{
          minHeight: "100vh",
          width: "100%",
        }}
      >
        {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
        <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />

        {data && <GeoJSON data={data.features} />}
        {/* {data && <MapInner area={area} data={data} />} */}
      </MapContainer>

      <svg
        viewBox={`0 0 ${area.width} ${area.height}`}
        className={styles.svg}
        onClick={onClick}
      >
        {nodes.map((node) => {
          return (
            <circle
              key={`${node.x}${node.y}`}
              r="20"
              fill="red"
              cx={node.x}
              cy={node.y}
            />
          );
        })}
      </svg>
    </div>
  );
};
