import { Map } from "./components/map/Map";
import { Mapper } from "./components/map/Mapper";

function App() {
  return (
    <div className="App">
      <Map />
    </div>
  );
}

export default App;
