import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

declare global {
  interface Window {
    maps: any;
    d3: any;
    topojson: any;
    importScript: any;
  }
}

window.maps = window.maps || {
  loaded: {},
};

window.importScript = (url: string) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.addEventListener("load", () => resolve(script), false);
    script.addEventListener("error", () => reject(script), false);
    document.body.appendChild(script);
  }).catch((err) => {
    console.log("Could not import script ", url, err);
  });
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
