/* @ts-ignore */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  RefObject,
  useMemo,
} from "react";
import cx from "classnames";
import styles from "./Map.module.css";
import {
  MapContainer,
  // TileLayer,
  LayerGroup,
  // GeoJSON,
  useMap,
} from "react-leaflet";
import * as L from "leaflet";
import Proj from "proj4leaflet";
import "proj4";
import "proj4leaflet";
import "leaflet/dist/leaflet.css";
import { Area, MapInner } from "./MapInner";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS.js";
import GeoJSON from "ol/format/GeoJSON.js";
import { fromLonLat } from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Style, Fill, Stroke } from "ol/style";
import { Coordinate } from "ol/coordinate";
import Draw from "ol/interaction/Draw.js";
import Snap from "ol/interaction/Snap.js";
import Geometry from "ol/geom/Geometry";
import lineIntersect from "@turf/line-intersect";
import { difference, intersect, lineDistance, polygon } from "@turf/turf";
// import Polygon from "ol/geom/Polygon";

interface Props {
  area: Area;
  data: any;
  d3: any;
}

enum Trigger {
  ON = "ON",
  OFF = "OFF",
}

// interface Coords {
//   x: number;
//   y: number;
// }

function getBounds(state: any, path: any, w: any, h: any) {
  const b = path.bounds(state);
  const s = 1 / Math.max((b[1][0] - b[0][0]) / w, (b[1][1] - b[0][1]) / h);
  const t = [
    (w - s * (b[1][0] + b[0][0])) / 2,
    (h - s * (b[1][1] + b[0][1])) / 2,
  ];
  const rasterWidth = (b[1][0] - b[0][0]) * s;
  const rasterHeight = (b[1][1] - b[0][1]) * s;
  const rtranslateX = (w - rasterWidth) / 2;
  const rtranslateY = (h - rasterHeight) / 2;
  const raster = [rasterWidth, rasterHeight, rtranslateX, rtranslateY];
  return { s, t, raster };
}

function getArea(plot: RefObject<HTMLDivElement>) {
  if (!plot.current) {
    return {
      width: 0,
      height: 0,
    };
  }
  const box = plot.current.getBoundingClientRect();
  const width = box.width;
  const height = box.height;
  return {
    width,
    height,
  };
}

export const Mapper: React.FunctionComponent<Props> = ({ d3, area, data }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [nodes, setNodes] = useState<Coordinate[]>([]);
  // const pathRef = useRef<any>();
  const hasRequested = useRef<boolean>(false);
  const map = useRef<any>(null);
  const [path, setPath] = useState<any>();
  const [transform, setTransform] = useState<any>();
  const pathRef = useRef<any>();
  const [trigger, setTrigger] = useState<Trigger>(Trigger.ON);
  const [moving, setMoving] = useState<boolean>(true);
  const raster = useRef<any>();
  const vector = useRef<any>();
  const vectorOverlay = useRef<VectorLayer<VectorSource<Geometry>>>(
    new VectorLayer({
      source: new VectorSource(),
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0.5)",
        }),
        stroke: new Stroke({
          color: "white",
        }),
      }),
    })
  );

  useEffect(() => {
    if (!d3) {
      return;
    }

    raster.current = new TileLayer({
      source: new XYZ({
        // projection: "EPSG:3857",
        // url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      }),
    });

    vector.current = new VectorLayer({
      source: new VectorSource({
        format: new GeoJSON(),
        url: "/maps/ficker.geojson",
      }),
      style: new Style({
        fill: new Fill({
          color: "rgba(255, 255, 255, 0)",
        }),
        stroke: new Stroke({
          color: "rgba(255, 255, 255, 0)",
        }),
      }),
    });

    const mapInstance = new Map({
      target: "map",
      layers: [
        raster.current,
        vector.current,
        vectorOverlay.current,
        // new TileLayer({
        //   source: new TileWMS({
        //     projection: "EPSG:3857", // here is the source projection
        //     url: "https://ahocevar.com/geoserver/wms",
        //     params: {
        //       LAYERS: "ne:NE1_HR_LC_SR_W_DR",
        //     },
        //   }),
        // }),
        // new TileLayer({
        //   source: new XYZ({
        //     // projection: "EPSG:3857",
        //     // url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        //     url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        //   }),
        // }),
        // new VectorLayer({
        //   source: new VectorSource({
        //     format: new GeoJSON(),
        //     url: "/maps/ficker.geojson",
        //   }),
        //   style: new Style({
        //     fill: new Fill({
        //       color: "yellow",
        //     }),
        //     stroke: new Stroke({
        //       color: "yellow",
        //     }),
        //   }),
        // }),
      ],
      view: new View({
        center: fromLonLat([17.702035062377554, 62.74906209058845]),
        zoom: 15,
      }),
    });

    const source = vector.current.getSource();
    if (!source) {
      return;
    }

    const drawLayer = new Draw({
      source,
      type: "Polygon",
    });

    mapInstance.addInteraction(drawLayer);

    drawLayer.on("drawend", (ev) => {
      const format = new GeoJSON();
      const geojsonStr = format.writeFeatures([ev.feature]);
      const tDrawnPoly = format.writeFeatureObject(ev.feature);
      // const length = lineDistance(tDrawnPoly, { units: "kilometers" });
      // console.log("length", length);
      // console.log("geojsonStr", geojsonStr);
      // console.log("tDrawnPoly", tDrawnPoly);
      const tDrawnPolygon = polygon((tDrawnPoly.geometry as any).coordinates);
      let drawn = tDrawnPolygon;

      // const poly = format.readFeature(ev.feature);
      // poly.setId("poly");

      const source = vectorOverlay.current?.getSource();
      source?.forEachFeature((feature) => {
        const tPoly = format.writeFeatureObject(feature);
        // const tPoly = format.writeFeatureObject(ev.feature);
        // const drawnPolygon = ev.feature.getGeometry();
        // if (!drawnPolygon) {
        //   return;
        // }
        const tPolygon = polygon((tPoly.geometry as any).coordinates);
        // const geo = feature.getGeometry();
        // if (!geo) {
        //   return;
        // }

        // const tPoly = new Polygon(geo.getExtent());
        // console.log("geo.getExtent()", geo.getExtent());
        // const polygon = feature.getGeometry();
        // const intersections = lineIntersect(poly, test);
        const intersections = difference(drawn, tPolygon);
        // const diff = format.readFeature(intersections);
        if (intersections) {
          drawn = polygon((intersections.geometry as any).coordinates);
          // drawn = format.writeFeatureObject(ev.feature)
        }
        // console.log("diff", intersections);
      });
      // source?.addFeature(ev.feature);
      source?.addFeature(format.readFeature(drawn));
    });

    // mapInstance.on("click", function (event) {
    //   setNodes((prev) => [
    //     ...prev,
    //     mapInstance.getCoordinateFromPixel(event.pixel),
    //   ]);
    // });

    const snap = new Snap({
      source: vector.current.getSource(),
    });
    mapInstance.addInteraction(snap);

    mapInstance.on("movestart", () => {
      // console.log("movestart");
      setMoving(true);
    });

    mapInstance.on("moveend", () => {
      // console.log("moveend");
      const projection = window.d3.geoTransform({
        point: function (x: number, y: number) {
          const coords = fromLonLat([x, y]);
          const point = map.current.getPixelFromCoordinate(coords);
          this.stream.point(point[0], point[1]);
        },
      });
      const pathFunc = window.d3.geoPath().projection(projection);
      pathRef.current = pathFunc;
      setMoving(false);
      // setTrigger((prev) => (prev === Trigger.ON ? Trigger.OFF : Trigger.ON));
    });

    map.current = mapInstance;
  }, [d3]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!d3) {
      return;
    }
    // const transformFunc = window.d3.geoTransform({ point: project });
    // const pathFunc = window.d3.geoPath().projection(transformFunc);
    // pathRef.current = pathFunc;
    // setPath(pathFunc);
    // setTransform(transformFunc);

    // function projectPoint(x: number, y: number) {
    //   console.log("x", x);
    //   console.log("y", y);
    //   const coords = fromLonLat([x, y]);
    //   // console.log('fromLonLat([]', fromLonLat([]);
    //   // const point = map.latLngToLayerPoint(new L.LatLng(y, x));
    //   console.log("coords", coords);
    //   console.log(
    //     "map.getPixelFromLonLat(coords);",
    //     map.current.getPixelFromCoordinate(coords)
    //   );
    //   const point = map.current.getPixelFromCoordinate(coords);
    //   console.log("point", point);
    //   (this as any)?.stream.point(point[0], point[1]);
    // }

    // function addStream(projection: any) {
    //   function projectPoint(x: number, y: number) {
    //     const coords = fromLonLat([x, y]);
    //     console.log("coords", coords);
    //     const point = map.current.getPixelFromCoordinate(coords);
    //     projection().stream.point(point[0], point[1]);
    //   }

    //   projection.geoTransform({ point: projectPoint });
    // }

    // map.current.getView().on("change:resolution", (event: any) => {
    //   const projection = window.d3.geoTransform({
    //     point: function (x: number, y: number) {
    //       const coords = fromLonLat([x, y]);
    //       const point = map.current.getPixelFromCoordinate(coords);
    //       this.stream.point(point[0], point[1]);
    //     },
    //   });
    //   const pathFunc = window.d3.geoPath().projection(projection);
    //   setPath(pathFunc);

    //   pathRef.current = pathFunc;
    // });

    // Use d3's custom geo transform method to implement the above
    // const projection = window.d3.geoTransform({
    //   point: function (x: number, y: number) {
    //     const coords = fromLonLat([x, y]);
    //     const point = map.current.getPixelFromCoordinate(coords);
    //     if (!point) {
    //       return;
    //     }
    //     this.stream.point(point[0], point[1]);
    //   },
    // });
    // const projection = window.d3.geoTransform();
    // addStream(projection);
    // creates geopath from projected points (SVG)
    // const pathFunc = window.d3.geoPath().projection(projection);

    // const projection = window.d3.geoMercator().scale(1).translate([0, 0]);
    // let pathFunc = window.d3.geoPath().projection(projection);
    // const bounds = pathFunc.bounds(data);
    // const scale =
    //   1 /
    //   Math.max(
    //     (bounds[1][0] - bounds[0][0]) / area.width,
    //     (bounds[1][1] - bounds[0][1]) / area.height
    //   );
    // const transform = [
    //   (area.width - scale * (bounds[1][0] + bounds[0][0])) / 2,
    //   (area.height - scale * (bounds[1][1] + bounds[0][1])) / 2,
    // ];
    // projection.scale(scale).translate(transform);
    // pathFunc = window.d3.geoPath().projection(projection);
    // setPath(pathFunc);
    // pathRef.current = pathFunc;
    // setTrigger((prev) => (prev === Trigger.ON ? Trigger.OFF : Trigger.ON));
  }, [area, d3, data]);

  // useEffect(() => {
  //   if (!d3) {
  //     return;
  //   }

  //   console.log("map.current", map.current);

  //   map.current.on("moveend", () => {
  //     console.log("moveend");
  //     const projection = window.d3.geoTransform({
  //       point: function (x: number, y: number) {
  //         const coords = fromLonLat([x, y]);
  //         const point = map.current.getPixelFromCoordinate(coords);
  //         this.stream.point(point[0], point[1]);
  //       },
  //     });
  //     const pathFunc = window.d3.geoPath().projection(projection);
  //     pathRef.current = pathFunc;
  //     setTrigger((prev) => (prev === Trigger.ON ? Trigger.OFF : Trigger.ON));
  //   });
  // }, [d3]);

  // useEffect(() => {
  //   if (hasRequested.current) {
  //     return;
  //   }

  //   hasRequested.current = true;

  //   const promises = [
  //     fetch("/maps/ficker.geojson", {
  //       // fetch("/maps/border_sweref99.geojson", {
  //       mode: "cors",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //     }),
  //     window.importScript("/libs/d3_7.8.4.min.js"),
  //   ];

  //   Promise.all(promises)
  //     .then((responses) => {
  //       responses[0].json().then((json: any) => {
  //         setData(json);
  //         setArea(getArea(mapRef));
  //       });
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // }, []);

  // const onClick = useCallback((ev: any) => {
  //   console.log("ev", ev);
  //   setNodes((prev) => [...prev, { x: ev.clientX, y: ev.clientY }]);
  // }, []);

  // SWEREF99 TM (EPSG:3006) with map's pixel origin at SWEREF99 TM coordinate (0, 0)
  // var crs = new L.Proj.CRS(
  //   "EPSG:3006",
  //   "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
  //   {
  //     resolutions: [8192, 4096, 2048, 1024, 512, 256, 128],
  //     origin: [0, 0],
  //   }
  // );

  const highlightedArea = useMemo(() => {
    if (moving) {
      return;
    }

    if (nodes.length < 3) {
      return;
    }

    let path = "";
    nodes.forEach((node, idx) => {
      const coords = map.current.getPixelFromCoordinate(node);
      if (idx === 0) {
        path = `M${coords[0]} ${coords[1]}`;
      } else {
        path = `${path} L${coords[0]} ${coords[1]}`;
      }
    });
    return `${path} Z`;
  }, [nodes, moving]);

  return (
    <>
      <div
        id="map"
        style={{
          width: "100%",
          height: "100vh",
        }}
      />
      <svg
        viewBox={`0 0 ${area.width} ${area.height}`}
        className={cx(styles.svg, {
          [styles.moving]: moving,
        })}
      >
        {!!trigger &&
          pathRef.current &&
          (data as any).features.map((d: any, i: number) => {
            return (
              <path
                key={`path-${i}`}
                d={pathRef.current(d)}
                className={styles.land}
              />
            );
          })}

        {highlightedArea && (
          <path d={highlightedArea} className={styles.highlightedArea} />
        )}

        {nodes.map((node) => {
          const coords = map.current.getPixelFromCoordinate(node);
          return (
            <circle
              key={`${coords.join()}`}
              cx={coords[0]}
              cy={coords[1]}
              r={8}
            />
          );
        })}

        {/* {!!trigger &&
          pathRef.current &&
          (nodes as any).features.map((d: any, i: number) => {
            return (
              <path
                key={`path-${i}`}
                d={pathRef.current(d)}
                className={styles.activeArea}
              />
            );
          })} */}
      </svg>
    </>
  );
};
